function loadCSS() {
  import('../sass/7-bundles/index.scss')
}

function loadPreloader() {
  import('../scripts/preloader.js')
}

function loadAnim() {
  import('../scripts/anim.js')
}

// function loadKnot() {
//   import('../knot/knot.js')
// }

function loadCube() {
  import('../cube/cube.js')
}

function loadGallery() {
  import('../scripts/gallery.js')
}

window.addEventListener('load', async function loadModules() {
  await loadCSS()
  await loadPreloader()
  await loadAnim()
  await loadCube()
  await loadGallery()
})